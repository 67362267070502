import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from "react-hook-form";

const OrcamentoBox = (props) => {
    const {
        toggleClasses,        
        products,
        setOpen,
        handleBudget,
        handleOrcamentoClick
    } = props;
    let producsItems = products || [];
    const { register, setValue, handleSubmit } = useForm();
    
    const handleClickItem = (el, type, e = null) => {
        if(e && e.target.value === '') setValue(`item__${el.ModelCode}`, 1);
        handleOrcamentoClick(el, type, e);
        if(!e) setValue(`item__${el.ModelCode}`, el.Quantity);
    };
    const onSubmit = (values) => {
        setOpen(true);
    };
    return (
        <>
            <div className="box-orcamento">
                <h3 className="orcamento-title">ORÇAMENTO<CloseIcon onClick={toggleClasses} className="close-box-orcamento" /></h3>
                <p className="orcamento-label">Clique em um item para removê-lo</p>
                {/* <form onSubmit={(e) => {
                    e.preventDefault();
                    setOpen(true);
                }}> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <table>
                        <thead>
                            <tr>
                                <td>ITEM</td>
                                <td>QUANT.</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                producsItems.map((el, i) => {
                                    if(el.isUsed) {
                                        return (
                                            <tr key={i}>
                                                <td><div
                                                onClick={() => handleBudget(el, 'remove')}
                                                onKeyDown={() => handleBudget(el, 'remove')}
                                                role="button" tabIndex={-1} style={{ outline: "none" }}>{el.Description}</div></td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div 
                                                        className="less-button"
                                                        onClick={() => handleClickItem(el, 'minus')}
                                                        onKeyDown={() => handleClickItem(el, 'minus')}
                                                        role="button"
                                                        tabIndex={-1}>
                                                            -
                                                    </div>
                                                    <input
                                                        aria-label="quantidade-input" 
                                                        onClick={(e) => e.target.select()}
                                                        onKeyUp={(e) => handleClickItem(el, 'change', e)}
                                                        name={`item__${el.ModelCode}`}
                                                        ref={register}
                                                        defaultValue={isNaN(el.Quantity) ? 1 : el.Quantity} 
                                                        className="orcamento-box-input"
                                                        type="number"
                                                    />
                                                    <div className="plus-button"
                                                    onClick={() => handleClickItem(el, 'plus')}
                                                    onKeyDown={() => handleClickItem(el, 'plus')}
                                                    role="button" tabIndex={-1}>+</div>
                                                </td>
                                            </tr>
                                        )
                                    };
                                    return null;
                                })
                            }
                        </tbody>
                    </table>
                    <div className="button-box">
                        <button type="submit">SOLICITAR</button>
                    </div>
                </form>
            </div>
            <div className="open-box-orcamento" onClick={toggleClasses} onKeyDown={toggleClasses} role="button" tabIndex={0}>
                Orçamento
        </div>
        </>
    )
}

export default OrcamentoBox;